import uikit from "./uikit.js"
import components from "./components.js"
import Swiper, {
  EffectCoverflow,
  EffectFade,
  Navigation,
  Pagination,
  Thumbs,
} from "swiper"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import Headroom from "headroom.js"
import { CountUp } from "countup.js"
import WOW from "wow.js"
import anime from "animejs"

uikit()
components()
function uikitPageDropdown() {
  $(".js-uikit-pages-dropdown").on("click", (e) => {
    $(".front-ui-dropdown").toggleClass("active")
  })
}
uikitPageDropdown()

const preloader = document.querySelector(".preloader")
if (preloader) {
  preloader.classList.add("animateStart")
  setTimeout(() => {
    preloader.classList.add("animateEnd")
  }, 305)
  setTimeout(() => {
    preloader.classList.add("disabled")
  }, 610)
}

var app = {
  load() {
    console.log("load")
    const items = document.querySelectorAll(".after-loaded")
    items.forEach((item) => {
      setTimeout(() => {
        item.style.opacity = ""
        item.style.zIndex = ""
      }, 300)
    })
  },
  resized() {
    console.log("resized")
  },

  headerHeight() {
    const header = document.querySelector("header")
    if (header) {
      setTimeout(() => {
        const headerHeight = header.offsetHeight
        var r = document.querySelector(":root")

        r.style.setProperty("--header-height", headerHeight + "px")
      }, 305)
    }
  },
  headerSearchFn() {
    const searchBtn = document.querySelector(".js-search-btn")
    const searchWrp = document.querySelector(".js-main-search")
    const closeSearchBtn = document.querySelector(".js-close-search-btn")
    const closeSearchBtnOverlay = document.querySelector(
      ".js-close-search-btn-overlay"
    )
    if (searchBtn) {
      searchBtn.addEventListener("click", () => {
        searchWrp.classList.add("opened")
      })
      closeSearchBtn.addEventListener("click", () => {
        searchWrp.classList.remove("opened")
      })
      closeSearchBtnOverlay.addEventListener("click", () => {
        searchWrp.classList.remove("opened")
      })
    }
  },
  headerLanguageFn() {
    const headerLangBtn = document.querySelector(".js-header-lang-btn")

    if (headerLangBtn) {
      window.addEventListener("click", function (e) {
        if (document.querySelector(".js-header-lang").contains(e.target)) {
          headerLangBtn.parentNode.classList.toggle("opened")
        } else {
          headerLangBtn.parentNode.classList.remove("opened")
        }
      })
      window.addEventListener("click", function (e) {
        if (
          document.querySelector(".js-header-lang-wrapper").contains(e.target)
        ) {
          document
            .querySelector(".js-header-lang-wrapper")
            .parentNode.classList.add("opened")
        }
      })
    }
  },

  detailPageFn() {
    const detailPage = document.querySelector(".detail-page")
    if (detailPage) {
      document.querySelector("body").style.backgroundColor = "var(--bg-color)"
    }
  },

  heroSliderFn() {
    var heroSwiperBg = new Swiper(".js-hero-bg-slider", {
      loop: true,
      slidesPerView: 1,
      modules: [EffectFade],
      effect: "fade",
      watchSlidesProgress: true,
      speed: 1000,
    })
    var heroSwiperContent = new Swiper(".js-hero-content-slider", {
      loop: true,
      slidesPerView: 1,
      modules: [Thumbs, Navigation, Pagination, EffectCoverflow],
      navigation: {
        nextEl: ".hero .swiper-button-next",
        prevEl: ".hero .swiper-button-prev",
      },
      pagination: {
        el: ".hero .swiper-pagination",
        type: "bullets",
      },
      effect: "coverflow",
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 0,
        modifier: 1,
        slideShadows: false,
      },

      speed: 1000,
      thumbs: {
        swiper: heroSwiperBg,
      },
    })
  },
  headroomFn() {
    const header = document.querySelector("header")
    if (header) {
      var options = {
        offset: 0,
        offset: {
          up: 100,
          down: 100,
        },
        tolerance: 0,
        tolerance: {
          up: 5,
          down: 0,
        },
        classes: {
          initial: "header",
          pinned: "header--pinned",
          unpinned: "header--unpinned",
          top: "header--top",
          notTop: "header--not-top",
          bottom: "header--bottom",
          notBottom: "header--not-bottom",
          frozen: "header--frozen",
        },
      }
      var headroom = new Headroom(header, options)
      headroom.init()
    }
  },
  mainSliderFn() {
    var mainSlider = new Swiper(".js-main-slider", {
      loop: true,
      slidesPerView: 3.8,
      spaceBetween: 40,
      centeredSlides: true,
      modules: [Navigation],
      navigation: {
        nextEl: ".js-main-slider .swiper-button-next",
        prevEl: ".js-main-slider .swiper-button-prev",
      },
      breakpoints: {
        320: {
          slidesPerView: 1.2,
          spaceBetween: 15,
        },
        577: {
          slidesPerView: 1.6,
          spaceBetween: 25,
        },
        769: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1000: {
          slidesPerView: 2.6,
        },
        1275: {
          slidesPerView: 3.2,
        },
        1500: {
          slidesPerView: 3.8,
        },
      },

      speed: 700,
    })
  },

  countUpFn() {
    var countTupVal = $(".js-count-item")

    countTupVal.each(function (index) {
      var value = $(countTupVal[index]).html()
      var countUpV = new CountUp(countTupVal[index], value, {
        enableScrollSpy: true,
        scrollSpyDelay: 100,
        scrollSpyOnce: true,
        duration: 5,
        useEasing: true,
        suffix: countTupVal[index].getAttribute("data-suffix"),
      })
      countUpV.handleScroll()
    })
  },

  movinWordsFn() {
    const allText = document.querySelectorAll(".js-text-anim")
    const allTextSecond = document.querySelectorAll(".js-text-anim-second")
    allText.forEach((text) => {
      text.innerHTML = text.innerHTML.replace(
        /(^|<\/?[^>]+>|\s+)([^\s<]+)/g,
        '$1<span class="word">$2</span>'
      )
      setTimeout(() => {
        text.style.opacity = 1
        anime.timeline({ loop: false }).add({
          targets: ".js-text-anim .word",
          translateY: [40, 0],
          translateZ: 0,
          opacity: [0, 1],
          duration: 600,
          easing: "easeOutSine",
          delay: (el, i) => 300 * i,
        })
      }, 650)
    })
    allTextSecond.forEach((textSecond) => {
      textSecond.innerHTML = textSecond.innerHTML.replace(
        /(^|<\/?[^>]+>|\s+)([^\s<]+)/g,
        '$1<span class="word">$2</span>'
      )
      setTimeout(() => {
        textSecond.style.opacity = 1
        anime.timeline({ loop: false }).add({
          targets: ".js-text-anim-second .word",
          translateY: [20, 0],
          translateZ: 0,
          opacity: [0, 1],
          duration: 600,
          easing: "easeOutSine",
          delay: (el, i) => 150 * i,
        })
      }, 650)
    })
  },

  wowFn() {
    var wow = new WOW({
      boxClass: "reveal-effect",
      animateClass: "reveal-effect__animated",
      offset: 150,
      mobile: true,
      live: true,
      callback: function (box) {},
    })
    setTimeout(() => {
      wow.init()
    }, 305)
  },

  aboutHistorySliderFn() {
    var aboutHistorySwiper = new Swiper(".js-about-history-slider", {
      loop: false,
      slidesPerView: 8,
      spaceBetween: 0,
      speed: 700,
      breakpoints: {
        320: {
          slidesPerView: 1.15,
          spaceBetween: 20,
          autoHeight: true,
        },
        577: {
          slidesPerView: 3,
          spaceBetween: 100,
          autoHeight: false,
        },
        769: {
          slidesPerView: 6,
          spaceBetween: 100,
        },
        993: {
          slidesPerView: 8,
          spaceBetween: 0,
        },
      },
    })
  },

  aboutTestimonialSliderFn() {
    var aboutTestimonialContent = new Swiper(".js-about-testimonial-content", {
      loop: true,
      slidesPerView: 1,
      modules: [EffectFade],
      effect: "fade",
      watchSlidesProgress: true,
      speed: 700,
      simulateTouch: false,
      onlyExternal: true,
      noSwiping: true,
      allowTouchMove: false,
      breakpoints: {
        320: {
          autoHeight: true,
        },
        993: {
          autoHeight: false,
        },
      },
    })
    var aboutTestimonialImage = new Swiper(".js-about-testimonial-image", {
      loop: true,
      slidesPerView: 1,
      modules: [Thumbs, Navigation],
      navigation: {
        nextEl: ".about-testimonial .swiper-button-next",
        prevEl: ".about-testimonial .swiper-button-prev",
      },
      speed: 700,
      thumbs: {
        swiper: aboutTestimonialContent,
      },
      breakpoints: {
        320: {
          slidesPerView: 1.2,
          spaceBetween: 20,
        },
        993: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
      },
    })
  },
  imageGridSliderFn() {
    var imageGridSlider = new Swiper(".js-image-grid-slider", {
      loop: true,
      slidesPerView: 1,
      modules: [Pagination],
      pagination: {
        el: ".js-image-grid-slider .swiper-pagination",
        type: "bullets",
        clickable: true,
      },

      speed: 700,
    })
  },

  accordionFn() {
    $("body").on("click", ".js-accordion-trigger", function () {
      $(this).toggleClass("active")
      const accordionGroup = $(this).closest(".accordion-wrapper")
      const item = $(this).closest(".accordion-item")
      let multipleShow = false

      if (accordionGroup.data("multiple-show") == true) {
        multipleShow = true
      } else {
        $(".js-accordion-trigger").not(this).removeClass("active")
      }
      let content = item.find(".accordion-calc")
      let contentHeight = content.outerHeight(true)
      console.log(contentHeight)
      if (item.hasClass("active") && !$(this).hasClass("force-open")) {
        item.find(".accordion-content").css("height", 0 + "px")
        item.removeClass("active")
      } else {
        if (!multipleShow) {
          accordionGroup.children(".accordion-item").removeClass("active")
          accordionGroup
            .children(".accordion-item")
            .find(".accordion-content")
            .css("height", 0 + "px")
        }
        item.addClass("active")
        item.find(".accordion-content").css("height", contentHeight)
      }
    })

    $(".accordion-item.opened .js-accordion-trigger").trigger("click")
  },
  mobileMenuFn() {
    const menuBtn = document.querySelector(".js-open-menu")
    const menu = document.querySelector(".js-menu")
    menuBtn.addEventListener("click", () => {
      menuBtn.classList.toggle("opened")
      menu.classList.toggle("opened")
      document.querySelector("html").classList.toggle("no-scroll")
      document.querySelector(".header").classList.toggle("header--with-menu")
    })
    const headerTitle = document.querySelectorAll(".js-mobile-menu-list-item")
    headerTitle.forEach((el) => {
      el.addEventListener("click", function () {
        const elParent = el.closest(".mobile-menu__list-inner")
        if (elParent.classList.contains("opened")) {
          elParent.classList.remove("opened")
          elParent.querySelector(".mobile-menu__list-dropdown").style.height =
            0 + "px"
        } else {
          document
            .querySelectorAll(".mobile-menu__list-inner.opened")
            .forEach((element) => {
              element.classList.remove("opened")
              element.querySelector(
                ".mobile-menu__list-dropdown"
              ).style.height = 0 + "px"
            })
          elParent.classList.add("opened")
          const listHeight = elParent.querySelector(
            ".js-mobile-menu-dropdown-list"
          ).offsetHeight
          elParent.querySelector(".mobile-menu__list-dropdown").style.height =
            listHeight + "px"
        }
      })
    })
  },

  init: function () {
    app.load()
    app.headerHeight()
    app.headerSearchFn()
    app.headerLanguageFn()
    app.detailPageFn()
    app.heroSliderFn()
    app.headroomFn()
    app.mainSliderFn()
    app.countUpFn()
    app.movinWordsFn()
    app.wowFn()
    app.aboutHistorySliderFn()
    app.aboutTestimonialSliderFn()
    app.imageGridSliderFn()
    app.accordionFn()
    app.mobileMenuFn()
  },
}

function docReadied(fn) {
  window.addEventListener("DOMContentLoaded", fn)
}
function docResized(fn) {
  window.addEventListener("resize", fn)
}
docReadied(() => {
  app.init()
})

docResized(() => {
  app.resized()
})

export default { docResized, docReadied }

const icons = [
  {
    iconId: "iconFacebook",
    iconSvg: `<svg viewBox="0 0 13 25" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0974 13.5989L12.7679 9.22176H8.57015V6.38394C8.57015 5.18931 9.15566 4.01829 11.035 4.01829H12.9426V0.297476C12.9426 0.297476 11.2097 0 9.55701 0C6.10062 0 3.84358 2.0965 3.84358 5.88814V9.22176H0V13.5989H3.84358V24.1758H8.57487V13.5989H12.0974Z" />
    </svg>`,
  },
  {
    iconId: "iconInstagram",
    iconSvg: `<svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.1621 5.89118C8.73071 5.89118 5.96298 8.65891 5.96298 12.0903C5.96298 15.5216 8.73071 18.2893 12.1621 18.2893C15.5934 18.2893 18.3611 15.5216 18.3611 12.0903C18.3611 8.65891 15.5934 5.89118 12.1621 5.89118ZM12.1621 16.1205C9.94463 16.1205 8.13184 14.3131 8.13184 12.0903C8.13184 9.86743 9.93923 8.06004 12.1621 8.06004C14.3849 8.06004 16.1923 9.86743 16.1923 12.0903C16.1923 14.3131 14.3795 16.1205 12.1621 16.1205ZM20.0606 5.6376C20.0606 6.44149 19.4132 7.08351 18.6147 7.08351C17.8108 7.08351 17.1688 6.43609 17.1688 5.6376C17.1688 4.83911 17.8162 4.19169 18.6147 4.19169C19.4132 4.19169 20.0606 4.83911 20.0606 5.6376ZM24.1663 7.10509C24.0746 5.16822 23.6322 3.45255 22.2133 2.03901C20.7997 0.62547 19.0841 0.183064 17.1472 0.0859508C15.151 -0.0273482 9.16772 -0.0273482 7.1715 0.0859508C5.24002 0.177669 3.52435 0.620075 2.10542 2.03361C0.686481 3.44715 0.24947 5.16283 0.152357 7.0997C0.0390581 9.09592 0.0390581 15.0792 0.152357 17.0754C0.244075 19.0123 0.686481 20.728 2.10542 22.1415C3.52435 23.555 5.23463 23.9974 7.1715 24.0946C9.16772 24.2078 15.151 24.2078 17.1472 24.0946C19.0841 24.0028 20.7997 23.5604 22.2133 22.1415C23.6268 20.728 24.0692 19.0123 24.1663 17.0754C24.2796 15.0792 24.2796 9.10131 24.1663 7.10509ZM21.5874 19.2173C21.1666 20.2748 20.3519 21.0894 19.2891 21.5156C17.6975 22.1469 13.9209 22.0012 12.1621 22.0012C10.4032 22.0012 6.62119 22.1415 5.035 21.5156C3.97755 21.0948 3.16287 20.2802 2.73665 19.2173C2.10542 17.6257 2.25109 13.8491 2.25109 12.0903C2.25109 10.3314 2.11081 6.54939 2.73665 4.9632C3.15748 3.90575 3.97215 3.09107 5.035 2.66485C6.62659 2.03361 10.4032 2.17928 12.1621 2.17928C13.9209 2.17928 17.7029 2.03901 19.2891 2.66485C20.3466 3.08568 21.1612 3.90035 21.5874 4.9632C22.2187 6.55478 22.073 10.3314 22.073 12.0903C22.073 13.8491 22.2187 17.6311 21.5874 19.2173Z" />
    </svg>`,
  },
  {
    iconId: "iconLinkedin",
    iconSvg: `<svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.58837 24.1758H0.575114V8.03523H5.58837V24.1758ZM3.07904 5.8335C1.47631 5.8335 0.175781 4.50599 0.175781 2.90326C0.175781 1.30053 1.47631 0 3.07904 0C4.68177 0 5.9823 1.30053 5.9823 2.90326C5.9823 4.50599 4.68177 5.8335 3.07904 5.8335ZM24.3462 24.1758H19.3438V16.3187C19.3438 14.4461 19.306 12.0448 16.7373 12.0448C14.1309 12.0448 13.7315 14.0792 13.7315 16.1838V24.1758H8.72367V8.03523H13.5319V10.237H13.602C14.2712 8.96881 15.9063 7.6305 18.3454 7.6305C23.4181 7.6305 24.3516 10.9709 24.3516 15.3096V24.1758H24.3462Z" />
    </svg>`,
  },
  {
    iconId: "iconYoutube",
    iconSvg: `<svg viewBox="0 0 35 25" xmlns="http://www.w3.org/2000/svg">
    <path d="M34.2662 3.78377C33.8677 2.29167 32.6977 1.12065 31.2115 0.724016C28.5174 0 17.7218 0 17.7218 0C17.7218 0 6.91994 0 4.22579 0.724016C2.73959 1.12065 1.56959 2.29167 1.17116 3.78377C0.450195 6.47837 0.450195 12.1131 0.450195 12.1131C0.450195 12.1131 0.450195 17.7415 1.17116 20.4424C1.56959 21.9283 2.73959 23.0552 4.22579 23.4518C6.91994 24.1758 17.7218 24.1758 17.7218 24.1758C17.7218 24.1758 28.5237 24.1758 31.2178 23.4518C32.7041 23.0552 33.8741 21.9283 34.2725 20.4424C34.9935 17.7415 34.9935 12.1131 34.9935 12.1131C34.9935 12.1131 34.9871 6.47837 34.2662 3.78377ZM14.1865 17.2253V7.00092L23.2176 12.1131L14.1865 17.2253Z" />
    </svg>`,
  },
  {
    iconId: "iconWhatsapp",
    iconSvg: `<svg viewBox="0 0 18 18"  xmlns="http://www.w3.org/2000/svg">
    <path  d="M15.2813 2.61584C13.5998 0.929883 11.3635 0.000988768 8.98116 0C4.07201 0 0.0766814 4.00111 0.0747068 8.91881C0.0740486 10.4908 0.484113 12.0254 1.26354 13.3781L0 18L4.72145 16.7596C6.0224 17.4703 7.48702 17.8449 8.97754 17.8453H8.98127C13.8899 17.8453 17.8856 13.8439 17.8875 8.92595C17.8885 6.54258 16.9629 4.3017 15.2813 2.61584ZM8.98116 16.339H8.97809C7.64982 16.3384 6.34711 15.9809 5.21039 15.3056L4.94019 15.1449L2.13841 15.881L2.88625 13.1453L2.71018 12.8648C1.96914 11.6844 1.57784 10.3201 1.5785 8.91936C1.58003 4.8319 4.90092 1.50645 8.98412 1.50645C10.9614 1.5071 12.8201 2.27922 14.2177 3.68053C15.6153 5.08184 16.3845 6.94446 16.3838 8.9254C16.3821 13.0132 13.0614 16.339 8.98116 16.339ZM13.0417 10.7866C12.8192 10.675 11.725 10.136 11.521 10.0615C11.3171 9.98712 11.1686 9.9501 11.0204 10.1731C10.872 10.3961 10.4456 10.8982 10.3157 11.0469C10.1858 11.1956 10.0561 11.2143 9.83354 11.1027C9.61096 10.9912 8.89395 10.7557 8.04387 9.99646C7.38237 9.40551 6.93577 8.67568 6.80589 8.45266C6.67622 8.22942 6.80479 8.12043 6.90352 7.99783C7.14442 7.69823 7.38566 7.38413 7.45982 7.23549C7.53408 7.08673 7.4969 6.95654 7.44117 6.84503C7.38566 6.73352 6.9406 5.63654 6.7552 5.19016C6.57442 4.75576 6.3911 4.81443 6.25442 4.80762C6.12475 4.80114 5.97632 4.79982 5.8279 4.79982C5.67958 4.79982 5.43846 4.85552 5.23441 5.07876C5.03048 5.30189 4.45564 5.84099 4.45564 6.93798C4.45564 8.03496 5.25306 9.0947 5.3643 9.24346C5.47553 9.39221 6.93358 11.6433 9.1659 12.6086C9.69685 12.8384 10.1113 12.9754 10.4346 13.0781C10.9677 13.2478 11.4527 13.2238 11.8363 13.1665C12.2639 13.1024 13.1528 12.6272 13.3384 12.1067C13.5238 11.5861 13.5238 11.1399 13.4681 11.0469C13.4126 10.9539 13.2641 10.8982 13.0417 10.7866Z"/>
    </svg>`,
  },
  {
    iconId: "iconTwitter",
    iconSvg: `<svg  viewBox="0 0 20 17"  xmlns="http://www.w3.org/2000/svg">
    <path d="M20 2.7629C19.2562 3.08607 18.4637 3.30029 17.6375 3.40429C18.4875 2.90158 19.1363 2.11161 19.4413 1.15942C18.6488 1.62746 17.7737 1.95806 16.8412 2.14256C16.0887 1.34887 15.0162 0.8573 13.8462 0.8573C11.5762 0.8573 9.74871 2.68242 9.74871 4.91986C9.74871 5.24179 9.77621 5.55134 9.84371 5.84604C6.435 5.68136 3.41875 4.06302 1.3925 1.59775C1.03875 2.20571 0.83125 2.90158 0.83125 3.65069C0.83125 5.0573 1.5625 6.30414 2.6525 7.02607C1.99375 7.01364 1.3475 6.82421 0.8 6.52579C0.8 6.53821 0.8 6.55429 0.8 6.57036C0.8 8.54407 2.22125 10.1835 4.085 10.5611C3.75125 10.6515 3.3875 10.6949 3.01 10.6949C2.7475 10.6949 2.4825 10.68 2.23375 10.6255C2.765 12.2339 4.2725 13.4164 6.065 13.4549C4.67 14.5358 2.89875 15.1871 0.98125 15.1871C0.645 15.1871 0.3225 15.1722 0 15.1314C1.81625 16.2916 3.96875 16.954 6.29 16.954C13.835 16.954 17.96 10.7629 17.96 5.39657C17.96 5.21703 17.9537 5.04368 17.945 4.87157C18.7587 4.29951 19.4425 3.58507 20 2.7629Z"/>
    </svg>
    `,
  },
  {
    iconId: "iconSearch",
    iconSvg: `<svg  viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg">
    <path d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"/>
    </svg>`,
  },
  {
    iconId: "iconAngle",
    iconSvg: `<svg  viewBox="0 0 10 15"  xmlns="http://www.w3.org/2000/svg">
    <path  d="M0.366117 14.6862C-0.122039 14.2678 -0.122039 13.5894 0.366117 13.171L6.98223 7.5L0.366118 1.82904C-0.122037 1.41062 -0.122037 0.732233 0.366118 0.313815C0.854274 -0.104605 1.64573 -0.104605 2.13389 0.313815L9.63388 6.74239C10.122 7.16081 10.122 7.8392 9.63388 8.25762L2.13388 14.6862C1.64573 15.1046 0.854272 15.1046 0.366117 14.6862Z"/>
    </svg>`,
  },
  {
    iconId: "iconArrow",
    iconSvg: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.4 27">
    <path class="st1" d="M25.7,17H1.5C0.7,17,0,16.3,0,15.5S0.7,14,1.5,14h24.2c0.8,0,1.5,0.7,1.5,1.5S26.5,17,25.7,17z"/>
    <path class="st1" d="M17.8,24.9c-0.4,0-0.8-0.1-1.1-0.4c-0.6-0.6-0.6-1.5,0-2.1l6.8-6.8l-6.8-6.8c-0.6-0.6-0.6-1.5,0-2.1 c0.6-0.6,1.5-0.6,2.1,0l7.9,7.9c0.6,0.6,0.6,1.5,0,2.1l-7.9,7.9C18.6,24.7,18.2,24.9,17.8,24.9z"/>
    </svg>`,
  },
  {
    iconId: "iconPhone",
    iconSvg: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.5 22.5">
    <path class="st0" d="M19.3,22.5c-0.1,0-0.2,0-0.3,0c-4.8-0.7-9.3-2.9-12.7-6.3C2.9,12.8,0.7,8.2,0,3.5c-0.1-0.5,0.1-1.1,0.4-1.5 C0.7,1.5,1.1,1.2,1.7,1l3.7-1c0.5-0.1,1-0.1,1.5,0.1c0.5,0.2,0.8,0.6,1.1,1.1l2,4.4c0.2,0.4,0.2,0.9,0.1,1.4C9.9,7.6,9.6,8,9.2,8.3 L7.6,9.6c1.3,2.1,3.2,3.9,5.3,5.3l1.3-1.6c0,0,0,0,0,0c0.3-0.4,0.7-0.6,1.2-0.8c0.5-0.1,1-0.1,1.4,0.1l4.4,2 c0.5,0.2,0.9,0.6,1.1,1.1c0.2,0.5,0.3,1,0.1,1.5l-1,3.7c-0.1,0.5-0.5,1-0.9,1.3C20.2,22.4,19.8,22.5,19.3,22.5z M5.9,1.5 c-0.1,0-0.1,0-0.2,0L2,2.5c-0.2,0-0.3,0.1-0.4,0.3C1.5,2.9,1.5,3.1,1.5,3.3c0.6,4.4,2.7,8.6,5.9,11.8c3.2,3.2,7.4,5.3,11.8,5.9 c0.2,0,0.3,0,0.5-0.1c0.1-0.1,0.3-0.2,0.3-0.4l1-3.7c0-0.2,0-0.3,0-0.5c-0.1-0.1-0.2-0.3-0.3-0.3L16.2,14c-0.1-0.1-0.3-0.1-0.5,0 c-0.1,0-0.3,0.1-0.4,0.2l-1.7,2.1c-0.2,0.3-0.6,0.4-1,0.2C9.9,14.9,7.6,12.6,6,9.8c-0.2-0.3-0.1-0.7,0.2-1l2.1-1.7 C8.4,7,8.5,6.9,8.6,6.8c0-0.1,0-0.3,0-0.5l-2-4.4C6.5,1.8,6.4,1.6,6.2,1.6C6.1,1.5,6,1.5,5.9,1.5z"/>
    </svg>`,
  },
  {
    iconId: "iconPlay",
    iconSvg: `<svg viewBox="0 0 26 30" xmlns="http://www.w3.org/2000/svg">
    <path d="M26 15L0.499999 29.7224L0.5 0.277567L26 15Z"/>
    </svg>`,
  },
  {
    iconId: "iconClose",
    iconSvg: `<svg  viewBox="0 0 86 85"  xmlns="http://www.w3.org/2000/svg">
    <rect x="63.5059" y="20.5061" width="2" height="60" transform="rotate(45 63.5059 20.5061)" />
    <rect x="21.0801" y="21.9203" width="2" height="60" transform="rotate(-45 21.0801 21.9203)" />
    </svg>`,
  },
]

export default icons
